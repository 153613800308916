
// 新闻详情页面
<template>
  <div class="share-wrap">
      <div class="share">
        <p class="share-to">分享至</p>
        <img class="wb" src="../../static/img/wb.png" />
        <img class="wx" src="../../static/img/wx.png" />
        <img class="qq" src="../../static/img/qq.png" />
      </div>
  </div>
</template>
<script>
export default {
  name: "share",
  data() {
    return {

    };
  },

  methods: {},
};
</script>

<style lang="less" scoped>
.share-wrap{
  z-index: 2;
    .share {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width:300px;
      // height: 40px;
      // background-color:pink ;

      .share-to {
        font-size: 14px;
        font-weight: 600;
        color: #303030;
        cursor: pointer;
      }
      .wb {
        margin-left: 40px;
        cursor: pointer;
        width: 34px;
        height: 28px;
      }
      .wx {
        margin-left: 40px;
        cursor: pointer;
        width: 34px;
        height: 28px;
      }
      .qq {
        margin-left: 40px;
        cursor: pointer;
        width: 26px;
        height: 30px;
      }
    }
}
@media only screen and (max-width: 750px) {
  .share-wrap{
    display: none;
  }
}
</style>