import Vue from 'vue'
import Vuex from 'vuex'
import {
  setToken
} from '@/apis/token.js'
import store from '@/store'
// console.log(store.state.token)


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    imgRoot:'http://hzwh.hnvtcloud.cn/ruoyi',//process.env.VUE_APP_BASE_API,  // 图片路径
    orderTime:{}, //  公众号选择的预约
    locale:window.sessionStorage.locale || 'zh',

  },
  mutations: {
    changeOrderTimer(state,data){
        state.orderTime = data
    },
    changeLocale(state,data){
      try{
        window.sessionStorage.locale= data;
        if(data == 'en'){
          window.sessionStorage.api = 'http://api.hzwhbwg.com/apien/'
        }else{
          window.sessionStorage.api = 'http://api.hzwhbwg.com/api/'
        }
      }catch {}
      //state.locale = data
    }



  },
  actions: {
  },
  modules: {
  }
})
