

//    全局组件
import Vue from 'vue'

import NavCom from "./navCom.vue"; // 路由导航
import Share from "./share/share.vue"; // 分享
import Paging from "./paging.vue"; // 分页

export default {
  install(Vue){
    Vue.component('NavCom',NavCom),
    Vue.component('Share',Share),
    Vue.component('Paging',Paging)
  }
}
