import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  getToken
} from '@/apis/token.js'


// 路由

const HomePage = ()=>import('../views/home/index.vue');  // 显示路由组件

const Home = ()=>import('../views/home/home.vue');  // 首页
const Overview = ()=>import('../views/overview/overview.vue'); // 概况
const Intro = ()=>import('../views/overview/intro/intro.vue');  // 简介
const Orgzt = ()=>import("../views/overview/orgzt/orgzt.vue");//  概况>组织架构
const Manage = ()=>import("../views/overview/manage/manage.vue"); // 概况>管理人员
const Matter = ()=>import("../views/overview/matter/matter.vue");   // 概况>大事略影


const Consult = ()=>import("../views/consult/consult.vue"); // 资讯
const News = ()=>import("../views/consult/news/news.vue");  // 资讯>新闻资讯
const Subject = ()=>import("../views/consult/subject/subject.vue"); //资讯>党建专题
const Notice = ()=>import("../views/consult/notice/notice.vue");  // 资讯>本馆公告
const consultDetails = ()=>import("../views/consult/consult_details.vue");  // 新闻资讯详情


const Exhibittion = ()=>import("../views/exhibition/exhibition.vue"); // 展览
const Often = ()=>import("../views/exhibition/often/often.vue");  // 展览>常设展览
const Outer = ()=>import("../views/exhibition/outer/outer.vue");  // 展览>赴外展览
const OuterDetail = ()=>import("../views/exhibition/outer/outer_detail.vue"); // 展览>赴外展览>详情
const Lookback = ()=>import("../views/exhibition/lookback/lookback.vue"); // 展览>展览回顾
const Recom = ()=>import("../views/exhibition/recom/recom.vue");  // 展览>新展推荐
const RecomDetail = ()=>import("../views/exhibition/recom/recom_detail.vue"); // 展览>新展推荐>详情

const Collection = ()=>import("../views/collection/collection.vue"); // 藏品
const Special = ()=>import("../views/collection/special/special.vue"); // 藏品>特别推荐
const Boutique = ()=>import("../views/collection/boutique/boutique.vue");  // 藏品》馆藏精品
const BouSort = ()=>import("../views/collection/boutique/bou_sort.vue"); // 藏品》馆藏精品>类型
const BouDetail = ()=>import("../views/collection/boutique/bou_detail.vue"); // 藏品》馆藏精品>类型>详情
const Abbr = ()=>import("../views/collection/abbr/abbr.vue");  // 藏品>3d全景
const Quantity = ()=>import("../views/collection/quantity/quantity.vue");  // 藏品>藏品总目
const QuList = ()=>import("../views/collection/quantity/qu_list.vue"); //  藏品>藏品总目>总目列表
   // import Inquire from "../views/collection/inquire/inquire.vue";  // 藏品>馆藏查询
   const Field = ()=>import("../views/collection/field/field.vue");  // 藏品>捐献园地
   const Fidetail = ()=>import("../views/collection/field/field_detail.vue"); // 藏品>捐赠园地>详情



   const Study = ()=>import("../views/study/study.vue"); // 研究
   const Work = ()=>import("../views/study/work/work.vue"); // 研究>期刊著作
   const WorkDetail = ()=>import("../views/study/work/work_detail.vue"); // 期刊论著详情
   const Paper = ()=>import("../views/study/paper/paper.vue"); // 研究>学术论文
   const Project = ()=>import("../views/study/project/project.vue"); // 研究>科研项目
   const ProDetail = ()=>import("../views/study/project/pro_detail.vue"); //  研究>科研项目>详情
   const Science = ()=>import("../views/study/science/science.vue"); // 研究>学术委员会

   const Activity = ()=>import("../views/activity/activity.vue"); // 活动
   const AcNotice = ()=>import("../views/activity/ac_notice/ac_notice.vue"); // 活动> 活动预告
   const AcSpecial = ()=>import("../views/activity/ac_special/ac_special.vue"); // 活动>  社教专题
   const Spdetail = ()=>import("../views/activity/ac_special/sp_detail.vue"); // 活动> 社教专题> 详情
   const Rest = ()=>import("../views/activity/rest/rest.vue");  // 活动> 其他活动
   const RestDetail = ()=>import("../views/activity/rest/rest_detail.vue"); // 活动 > 其他活动 > 详情
   const Chair = ()=>import("../views/activity/chair/chair.vue"); // 活动> 活动讲座
   const ChairDetail = ()=>import("../views/activity/chair/chair_detail.vue"); // 活动 > 活动讲座 > 详情

   const Service = ()=>import("../views/service/service.vue");  // 服务
   const Info = ()=>import("../views/service/info/info.vue"); //  服务 > 票务信息
   const Visit = ()=>import("../views/service/visit/visit.vue"); // 服务 > 游览须知
   const Traffic = ()=>import("../views/service/traffic/traffic.vue"); // 服务 > 交通路线
   const Postulant = ()=>import("../views/service/postulant/postulant.vue"); // 服务 > 志愿者之窗
   const Apply = ()=>import("../views/service/postulant/apply.vue");  // 服务 > 志愿者之窗 > 申请
const smallApply = ()=>import("../views/service/postulant/smallApply.vue");  // 服务 > 志愿者之窗 > 小志愿者申请

   const ContactUs = ()=>import("../views/service/contact _us/contact_us.vue"); // 联系我们


   const Digital = ()=>import("../views/digital/digital.vue"); // 数字化平台

   const Login = ()=>import("../views/login/login.vue"); // 登录
   const Signin = ()=>import("../views/account/signin.vue"); // 注册账号
   const FindPws = ()=>import("../views/account/findpws.vue"); // 找回密码

   const Personal = ()=>import("../views/personal/personal.vue"); // 个人中心
   const PerWrap = ()=>import("../views/personal/index.vue"); // 个人中心显示内容
   const Personage = ()=>import("../views/personal/personage/personage.vue"); // 个人预约
   const Group = ()=>import("../views/personal/group/group.vue");  // 团队预约
   const Unusual = ()=>import('../views/personal/unusual/unusual.vue');  // 特展预约
   const UnDetail = ()=>import("../views/personal/unusual/undetail.vue"); // 特展详情





Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    component:HomePage,
    redirect:'/home',
    children:[
      {
        path:'/home',
        name:'home',
        component:Home,
        meta:{
          current:0
        }
      },
      {
        path:'/overview',
        name:'overview',
        redirect:'/overview/intro',
        component:Overview,
        children:[
          {
            path:'intro',
            name:'intro',
            component:Intro,
            meta:{
              current:1
            }
          },
          {
            path:'orgzt',
            name:'orgzt',
            component:Orgzt,
            meta:{
              current:1
            }
          },
          {
            path:'manage',
            name:'manage',
            component:Manage,
            meta:{
              current:1
            }
          },
          {
            path:'matter',
            name:'matter',
            component:Matter,
            meta:{
              current:1
            }
          }
        ]

      },
      {
        path:'/consult',
        redirect:'/consult/news',
        name:'consult',
        component:Consult,
        children:[
          {
            path:'news',
            name:'news',
            component:News,
            meta:{
              current:2,
              newBg:true
            }
          },
          {
            path:'subject',
            name:'subject',
            component:Subject,
            meta:{
              current:2
            }
          },
          {
            path:'sublist',
            name:'sublist',
            component:()=>import('../views/consult/subject/sub_list.vue'),  // 党建动态列表
            meta:{
              current:2
            }
          },
          {
            path:'subdetail',
            name:'subdetail',
            component:()=>import('../views/consult/subject/sub_detail.vue'),  // 党建动态列表详情
            meta:{
              current:2
            }
          },
          {
            path:'openlist',
            name:'openlist',
            component:()=>import('../views/consult/subject/open_list.vue'),  //党建公开列表
            meta:{
              current:2
            }
          },
          {
            path:'opendetail',
            name:'opendetail',
            component:()=>import('../views/consult/subject/open_detail.vue'),  // 党建公开详情
            meta:{
              current:2
            }
          },
          {
            path:'notice',
            name:'notice',
            component:Notice,
            meta:{
              current:2
            }
          },
          {
            path:'noticedetail',
            name:'noticedetail',
            component:()=>import('../views/consult/notice/notice_detail.vue'), // 公告详情
            meta:{
              current:2
            }
          },
          {
            path:'details',
            name:'details',
            component:consultDetails,
            meta:{
              current:2
            }
          }

        ]
      },
      {
        path:'/exhibittion',
        name:'exhibittion',
        redirect:'/exhibittion/often',
        component:Exhibittion,
        children:[
          {
            path:'often',
            name:'often',
            component:Often,
            meta:{
              current:3
            }
          },
          {
            path:'oftendetail',
            name:'oftendetail',
            component:()=> import('../views/exhibition/often/often_detail.vue'),  // 常设展览
            meta:{
              current:3
            }
          },
          {
            path:'outer',
            name:'outer',
            component:Outer,
            meta:{
              current:3
            }
          },
          {
            path:'outerdetail',
            name:'outerdetail',
            component:OuterDetail,
            meta:{
              current:3
            }
          },
          {
            path:'lookback',
            name:'lookback',
            component:Lookback,
            meta:{
              current:3
            }
          },
          {
            path:'recom',
            name:'recom',
            component:Recom,
            meta:{
              current:3
            }
          },
          {
            path:'recomdetail',
            name:'recomdetail',
            component:RecomDetail,
            meta:{
              current:3
            }

          },
          {
            path:'panorama',
            name:'panorama',
            component:()=>import('../views/exhibition/panorama'),  // 全景博物馆
            meta:{
              current:3
            }
          }
        ]
      },
      {
        path:'/collection',
        name:'collection',
        redirect:'/collection/special',
        component:Collection,
        children:[
          {
            path:'special',
            name:'special',
            component:Special,
            meta:{
              current:4
            }
          },

          {
            path:"boutique",
            name:'boutique',
            component:Boutique,
            meta:{
              current:4
            }
          },
          {
            path:"sort",
            name:'sort',
            component:BouSort,
            meta:{
              current:4
            }
          },
          {
            path:"boudetail",
            name:'boudetail',
            component:BouDetail,
            meta:{
              current:4
            }
          },
          {
            path:'abbr',
            name:'abbr',
            component:Abbr,
            meta:{
              current:4
            }
          },
          // {
          //   path:
          // },
          {
            path:'quantity',
            name:'quantity',
            component:Quantity,
            meta:{
              current:4
            }
          },
          {
            path:'qulist',
            name:'qulist',
            component:QuList,
            meta:{
              current:4
            }
          },
          {
            path:'field',
            name:'field',
            component:Field,
            meta:{
              current:4
            }
          },
          {
            path:'fidetail',
            name:'fidetail',
            component:Fidetail,
            meta:{
              current:4
            }
          },
          {
            path:'fidnotice',
            name:'fidnotice',
            component:()=> import('../views/collection/field/field_notice.vue'),  // 捐赠园地公告
            meta:{
              current:4
            }
          }
        ]

      },
      {
        path:'/study',
        name:'study',
        redirect:'/study/work',
        component:Study,
        children:[
          {
            path:'work',
            name:'work',
            component:Work,
            meta:{
              current:5
            }
          },
          {
            path:'workdetail',
            name:'workdetail',
            component:WorkDetail,
            meta:{
              current:5
            }
          },
          {
            path:'paper',
            name:'paper',
            component:Paper,
            meta:{
              current:5
            }
          },
          {
            path:'project',
            name:'project',
            component:Project,
            meta:{
              current:5
            }
          },
          {
            path:'prodetail',
            name:'prodetail',
            component:ProDetail,
            meta:{
              current:5
            }
          },
          {
            path:'science',
            name:'science',
            component:Science,
            meta:{
              current:5
            }
          }
        ]
      },
      {
        path:'/activity',
        name:'activity',
        redirect:'/activity/acnotice',
        component:Activity,
        children:[
          {
            path:'acnotice',
            name:'acnotice',
            component:AcNotice,
            meta:{
              current:6
            }
          },
          {
            path:'acnodetail',
            name:'acnodetail',
            component:()=>import('../views/activity/ac_notice/acnotice_detail.vue'),
            meta:{
              current:6
            }
          },
          {
            path:'acspecial',
            name:'acspecial',
            component:AcSpecial,
            meta:{
              current:6
            }
          },
          {
            path:'spdetail',
            name:'spdetail',
            component:Spdetail,
            meta:{
              current:6
            }
          },
          {
            path:'rest',
            name:'rest',
            component:Rest,
            meta:{
              current:6
            }
          },
          {
            path:'restdetail',
            name:'restdetail',
            component:RestDetail,
            meta:{
              current:6
            }
          },
          {
            path:'chair',
            name:'chair',
            component:Chair,
            meta:{
              current:6
            }
          },
          {
            path:'chairdetail',
            name:'chairdetail',
            component:ChairDetail,
            meta:{
              current:6
            }
          }

        ]

      },
      {
        path:'/service',
        name:'service',
        redirect:'/service/info',
        component:Service,
        children:[
          {
            path:'info',
            name:'info',
            component:Info,
            meta:{
              current:7
            }
          },
          {
            path:'visit',
            name:'visit',
            component:Visit,
            meta:{
              current:7
            }
          },
          {
            path:'guidemap',
            name:'guidemap',
            component:()=>import('../views/service/guide_map/guide_map.vue'),  // 导览地图
            meta:{
              current:7
            }
          },
          {
            path:'traffic',
            name:'traffic',
            component:Traffic,
            meta:{
              current:7
            }
          },
          {
            path:'postulant',
            name:'postulant',
            component:Postulant,
            meta:{
              current:7
            }
          },
          {
            path:'zyzdt',
            name:'zyzdt',
            component:() => import('../views/service/postulant/zyzdt.vue'),  // 志愿者动态列表
            meta:{
              current:7
            }
          },
          {
            path:'dtdetail',
            name:'dtdetail',
            component:() => import('../views/service/postulant/dt_detail.vue'),  // 志愿者动态列表详情
            meta:{
              current:7
            }
          },
          {
            path:'apply',
            name:'apply',
            component:Apply,
            meta:{
              current:7
            }
          },
          {
            path:'smallApply',
            name:'smallApply',
            component:smallApply,
            meta:{
              current:7
            }
          },
          {
            path:'contactus',
            name:'contactus',
            component:ContactUs,
            meta:{
              current:7
            }
          }
        ]

      },
      {
        path:'/digital',
        name:'digital',
        component:Digital,

      },
      {
        path:'/signin',
        name:'signin',
        component:Signin,

      },
      {
        path:'/findpws',
        name:'findpws',
        component:FindPws,
      },
      {
        path:'/rule',
        name:'rule',
        component:()=>import('../views/account/rule.vue'),  //   规章制度
      },
      {
        path:'/mlogin',
        name:'mlogin',
        component:()=>import('../views/mobile_order/m_login.vue')   // 公众号登录
      },
      {
        path:'/mhome',
        name:'mhome',
        component:() => import('../views/mobile_order/m_home.vue'),   //  公众号预约首页
        meta:{
            loginType:true
        }
      },
      {
        path:'/morder',
        name:'morder',
        component:()=>import('../views/mobile_order/m_order.vue'), //   预约时间
        meta:{
          loginType:true
        }
      },
      {
        path:'/otime',
        name:'ordertime',
        component:()=>import('../views/mobile_order/order_time.vue'), //   预约填写信息
        meta:{
          loginType:true
        }
      },
      {
        path:'/onotice',
        name:'onotice',
        component:()=>import('../views/mobile_order/order_notice.vue'), //   预约须知
      },
      {
        path:'/win',
        name:'win',
        component:()=>import('../views/mobile_order/win.vue'), // 预约成功
      },
      {
        path:'/record',
        name:'record',
        component:()=>import('../views/mobile_order/record.vue'), // 预约记录
      },
      {
        path:'/scan',
        name:'scan',
        component:()=>import('../views/mobile_order/scan.vue')   // 扫一扫
      },
      {
        path:'/collspdetail',
        name:'/collspdetail',
        component:()=>import('../views/collection/special/sp_detail.vue'),  // 藏馆 > 特别推荐详情
        meta:{
          current:4
        }
      },

    ]
  },
  {
    path:'/login',
    name:'login',
    component:Login,  // 登录
  },
  {
    path:'/personal',
    name:'personal',
    redirect:'/personal/perindex',
    component:Personal,   //  个人中心
    children:[
      {
        path:'perindex',
        name:'perwrap',
        component:PerWrap
      },
      {
        path:'personage',
        name:'personage',
        component:Personage
      },
      {
        path:'group',
        name:'group',
        component:Group

      },
      {
        path:'unusual',
        name:'unusual',
        component:Unusual
      },
      {
        path:'undetail',
        name:'undetail',
        component:UnDetail
      }
    ]
  },
  {
    path:'/abbr3d',
    name:'addr3d',
    component:()=>import('../views/collection/abbr/abbr_3d.vue')    // 3d 查看页面
  },

  {
    path:'/mshow',
    name:'mshow',
    component:()=>import('../views/mobile_order/m_show.vue')  // 公众号展示
  },
  {
    path:'/lookhome',
    name:'lookhome',
    component:()=>import('../views/exhibition/lookback/look_home.vue'),  // 回顾首页
     meta:{
      current:3
    }
  },
  {
    path:'/lookdetail',
    name:'lookdetail',
    component:()=>import('../views/exhibition/lookback/look_detail.vue'),  // 回顾详情
    meta:{
      current:3
    }
  },

  {
    path:'*',
    redirect:'/home',
  }


]

const router = new VueRouter({
  // mode: 'history',
  routes,
  // scrollBehavior(to, from,savedPosition) {
  //   return {
  //     x: 0,
  //     y: 0
  //   }
  // }

})

// 解决点击重复路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}



router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);
  // 公众号登录拦截
   if(to.meta.loginType){
      if(!getToken()){
        next({path:'/mlogin'})
      }
   }

  if(to.meta.title){
    document.title = to.meta.title
  }else{
    document.title = '安徽中国徽州文化博物馆'
  }
    // chrome
    // document.body.scrollTop = 0;
    // // firefox
    // document.documentElement.scrollTop = 0;
    // // safari
    // window.pageYOffset = 0;
  next()
})




export default router
